myElement = document.querySelector("header")
headroom  = new Headroom(myElement,
  offset: 200
)
headroom.init()

window.dataLayer = window.dataLayer || []
grow_gtag = () ->
  window.dataLayer.push(arguments)

document.addEventListener 'DOMContentLoaded', () ->

  scroll = new SmoothScroll('a[data-scroll]', {
    speed: 500
    updateURL: false
    speedAsDuration: true
    offset: (a, t) ->
      return 50
  })

  jobsContainerEl = document.querySelector('.jobs-container')

  if jobsContainerEl?
    mixer = mixitup(jobsContainerEl,
      animation:
        animateResizeContainer: false
        animateResizeTargets: false
      selectors:
        target: '.col-12'
      controls:
        toggleLogic: 'or'
        toggleDefault: 'all'

    )

  exampleModal = document.getElementById('exampleModal')
  if exampleModal
    exampleModalM = bootstrap.Modal.getOrCreateInstance(exampleModal)

  priceModal = document.getElementById('priceModal')
  if priceModal
    priceModal.addEventListener('show.bs.modal', (event) ->
      button  = event.relatedTarget
      price   = button.getAttribute('data-sk-price')      
      priceModal.setAttribute('data-sk-price', price)
      #reg     = priceModal.getElementsByClassName('reg-btn')[0]
      #reg.setAttribute('data-sk-price', price)
    )
    priceModal.addEventListener('hide.bs.modal', (event) ->     
      priceModal.removeAttribute('data-sk-price')
      #reg     = priceModal.getElementsByClassName('reg-btn')[0]
      #reg.removeAttribute('data-sk-price')
    )
  

  requestModal = document.getElementById('requestModal')
  if requestModal
    requestModalM = bootstrap.Modal.getOrCreateInstance(requestModal)

    requestModal.addEventListener('show.bs.modal', (event) ->
      grow_gtag('event', 'book_package_start')
      button  = event.relatedTarget
      price   = button.getAttribute('data-sk-price')      

      plan    = requestModal.querySelector('.rff6')
      plan.value = price

      $('.festival-label').html($('.dropdown-menu input[type="checkbox"]:checked').eq(0).val())
    )

  $('.dropdown-menu input[type="checkbox"]').on 'change', (event) ->
    if $('.dropdown-menu input[type="checkbox"]:checked').length > 1
      $('.festival-label').html('mehrere ausgewählt')
    else if $('.dropdown-menu input[type="checkbox"]:checked').length == 1
      $('.festival-label').html($('.dropdown-menu input[type="checkbox"]:checked').eq(0).val())
    else
      $('.festival-label').html('bitte wählen')
  
  if $('#rForm').length > 0
    $rForm   = $('#rForm')
    
    $rForm.ajaxForm
      beforeSubmit: (arr, $form, options) ->
        # disable all controll elements
        $rForm.find('.form-control, .btn, .form-check-input, .form-select').prop 'disabled', () ->
          return !$(this).prop('disabled')   

      success: (responseText, statusText, xhr, $form) ->

        grow_gtag('event', 'book_package_finish')

        # enable all controll elements
        $rForm.find('.form-control, .btn, .form-check-input, .form-select').prop 'disabled', () ->
          return !$(this).prop('disabled')

        requestModalM.hide()
        exampleModalM.show()     

      error: (responseText, statusText, xhr, $form) ->
        # enable all controll elements
        $rForm.find('.form-control, .btn, .form-check-input, .form-select').prop 'disabled', () ->
          return !$(this).prop('disabled')

        jQuery.notify responseText.responseText,
          arrowShow: false
          autoHide: true
          position: 'bottom right'
          className: 'error'
          autoHideDelay: 3000
        
      url: 'core/request.php'
      type: 'post'
      clearForm: true
      resetForm: true 

  if $('#cForm').length > 0
    $cForm   = $('#cForm')
    
    $cForm.ajaxForm
      beforeSubmit: (arr, $form, options) ->
        # disable all controll elements
        $cForm.find('.form-control, .btn, .form-check-input, .form-select').prop 'disabled', () ->
          return !$(this).prop('disabled')   

      success: (responseText, statusText, xhr, $form) ->
        grow_gtag('event', 'contact_sent')

        # enable all controll elements
        $cForm.find('.form-control, .btn, .form-check-input, .form-select').prop 'disabled', () ->
          return !$(this).prop('disabled')

        exampleModalM.show()     

      error: (responseText, statusText, xhr, $form) ->
        # enable all controll elements
        $cForm.find('.form-control, .btn, .form-check-input, .form-select').prop 'disabled', () ->
          return !$(this).prop('disabled')

        jQuery.notify responseText.responseText,
          arrowShow: false
          autoHide: true
          position: 'bottom right'
          className: 'error'
          autoHideDelay: 3000
        
      url: 'core/contact.php'
      type: 'post'
      clearForm: true
      resetForm: true 

  if $('#spForm').length > 0
    $spForm   = $('#spForm')
    
    $spForm.ajaxForm
      beforeSubmit: (arr, $form, options) ->
        # disable all controll elements
        $spForm.find('.form-control, .btn, .form-check-input, .form-select').prop 'disabled', () ->
          return !$(this).prop('disabled')   

      success: (responseText, statusText, xhr, $form) ->
        grow_gtag('event', 'contact_sponsor_sent')

        # enable all controll elements
        $spForm.find('.form-control, .btn, .form-check-input, .form-select').prop 'disabled', () ->
          return !$(this).prop('disabled')

        exampleModalM.show()     

      error: (responseText, statusText, xhr, $form) ->
        # enable all controll elements
        $spForm.find('.form-control, .btn, .form-check-input, .form-select').prop 'disabled', () ->
          return !$(this).prop('disabled')

        jQuery.notify responseText.responseText,
          arrowShow: false
          autoHide: true
          position: 'bottom right'
          className: 'error'
          autoHideDelay: 3000
        
      url: 'core/sponsor.php'
      type: 'post'
      clearForm: true
      resetForm: true 

  
  if $('.imageSlider').length > 0


    $('.imageSlider').each (i, e) ->

      $this = $(e)

      $this
        .on 'init', (event, slick) -> 
          slick.$slider.find('.slider-amount-current').html(1)
          slick.$slider.find('.slider-amount-total').html(slick.slideCount)
        .on 'beforeChange', (event, slick, currentSlide, nextSlide) ->
          slick.$slider.find('.slider-amount-current').html(nextSlide + 1)
        .slick
          infinite: true
          dots: true
          dotsClass: 'slider-dots'
          slide: '.slick-slide'
          slidesToScroll: 1
          slidesToShow: 1
          arrows: true
          customPaging: (slider, i) -> 
            return false if slider.slideCount == 1 || slider.slideCount == slider.options.slidesToShow
            return "<a class='slider-dots-item'></a>"
          prevArrow: '<div class="ic ic-sm ic-control prev"><svg class="fi"><use xlink:href="assets/img/icons/grow.svg#chevron-left"/></svg></div>'
          nextArrow: '<div class="ic ic-sm ic-control next"><svg class="fi"><use xlink:href="assets/img/icons/grow.svg#chevron-right"/></svg></div>'
          useCSS: false
          cssEase: 'linear'

         

  if $('.imageSliderRow').length > 0


    $('.imageSliderRow').each (i, e) ->

      $this = $(e)

      $this
        .slick
          infinite: true
          dots: true
          dotsClass: 'slider-dots'
          customPaging: (slider, i) -> 
            return false if slider.slideCount == 1 || slider.slideCount == slider.options.slidesToShow
            return "<a class='slider-dots-item'></a>"
          arrows: true
          slidesToScroll: 1
          swipeToSlide: true
          prevArrow: '<div class="ic ic-sm ic-control prev"><svg class="fi"><use xlink:href="assets/img/icons/grow.svg#chevron-left"/></svg></div>'
          nextArrow: '<div class="ic ic-sm ic-control next"><svg class="fi"><use xlink:href="assets/img/icons/grow.svg#chevron-right"/></svg></div>'
          speed: 500
          autoplay: false
          autoplaySpeed: 5000
          useCSS: false
          cssEase: 'linear'
          focusOnSelect: true
        
          responsive: [
            {
              breakpoint: 992
              settings: 
                slidesToShow: 2
            }
            {
              breakpoint: 768
              settings: 
                slidesToShow: 1
            }
          ]


  if $('.videoSliderRow').length > 0


    $('.videoSliderRow').each (i, e) ->

      $this = $(e)

      $this
        .slick
          infinite: false
          dots: true
          dotsClass: 'slider-dots'
          customPaging: (slider, i) -> 
            return false if slider.slideCount == 1 || slider.slideCount == slider.options.slidesToShow
            return "<a class='slider-dots-item'></a>"
          arrows: true
          #slidesToShow: 4
          slidesToScroll: 1
          swipeToSlide: true
          prevArrow: '<div class="ic ic-sm ic-control prev"><svg class="fi"><use xlink:href="assets/img/icons/grow.svg#chevron-left"/></svg></div>'
          nextArrow: '<div class="ic ic-sm ic-control next"><svg class="fi"><use xlink:href="assets/img/icons/grow.svg#chevron-right"/></svg></div>'
          speed: 500
          autoplay: false
          autoplaySpeed: 5000
          useCSS: false
          cssEase: 'linear'
          focusOnSelect: true
        
          responsive: [
            {
              breakpoint: 1200
              settings: 
                slidesToShow: 3
                arrows: false
            }
            {
              breakpoint: 768
              settings: 
                slidesToShow: 2
                arrows: false
            }
            {
              breakpoint: 576
              settings: 
                slidesToShow: 1
                arrows: false
                centerMode: true
                centerPadding: '32px'
            }
          ]


  if $('.photoslider').length > 0

    $('.photoslider').each (i, e) ->
      $this = $(e)

      $this
        .slick
          infinite: true
          dots: false
          arrows: false
          slidesToShow: 2
          slidesToScroll: 1
          swipeToSlide: true
          speed: 500
          autoplay: true
          autoplaySpeed: 3000
          useCSS: false
          cssEase: 'linear'
          focusOnSelect: false
          pauseOnHover: false
          pauseOnFocus: false

  if $('.photoslider2').length > 0

    $('.photoslider2').each (i, e) ->
      $this = $(e)

      $this
        .slick
          infinite: true
          dots: false
          arrows: false
          variableWidth: true
          slidesToScroll: 1
          centerMode: false
          swipeToSlide: true
          speed: 500
          autoplay: true
          autoplaySpeed: 3000
          useCSS: false
          cssEase: 'linear'
          focusOnSelect: false
          pauseOnHover: false
          pauseOnFocus: false
          initialSlide: 4

  if $('.slider-event').length > 0

    $('.slider-event').each (i, e) ->
      $this = $(e)

      $this
        .on 'breakpoint', (event, slick, breakpoint) ->
          newPos = 0
          if breakpoint == 992 || breakpoint == 1400
            newPos = 1
          else if breakpoint == 768
            newPos = 1
          else if slick.currentSlide == 0
            newPos = 0
          slick.currentSlide = newPos
          return $this.slick('setPosition')

        .slick
          infinite: true
          dots: false
          arrows: true
          slidesToShow: 4
          slidesToScroll: 1
          swipeToSlide: true
          prevArrow: '<div class="slider-control slider-control-prev"><svg class="fi fi-fill"><use xlink:href="assets/img/icons/grow-sprite.svg#arrow-left"/></svg></div>'
          nextArrow: '<div class="slider-control slider-control-next"><svg class="fi fi-fill"><use xlink:href="assets/img/icons/grow-sprite.svg#arrow-right"/></svg></div>'
          appendArrows: '.slider-event-controls'
          speed: 500
          autoplay: false
          autoplaySpeed: 5000
          useCSS: false
          cssEase: 'linear'
          focusOnSelect: true

          responsive: [
            {
              breakpoint: 1400
              settings: 
                slidesToShow: 3
                slidesToScroll: 1
                initialSlide: 1
            }
            {
              breakpoint: 992
              settings: 
                slidesToShow: 2
                slidesToScroll: 1
                initialSlide: 1
            }
            {
              breakpoint: 768
              settings: 
                slidesToShow: 1
                slidesToScroll: 1
                centerMode: true
                centerPadding: '32px'
                initialSlide: 1
            }
          ]



  if $('.slider').length > 0
    $('.slider').each (i, e) ->
      $this = $(e)

      $this.slick
        infinite: true
        dots: false
        arrows: true
        slidesToShow: 4
        slidesToScroll: 2
        prevArrow: '.slider-control-prev'
        nextArrow: '.slider-control-next'
        speed: 500
        fade: false
        autoplay: false
        autoplaySpeed: 5000
        useCSS: false
        cssEase: 'linear'

        responsive: [
          {
            breakpoint: 1400
            settings: 
              slidesToShow: 3
              slidesToScroll: 3
          }
          {
            breakpoint: 992
            settings: 
              slidesToShow: 2
              slidesToScroll: 2
          }
          {
            breakpoint: 576
            settings: 
              slidesToShow: 1
              slidesToScroll: 1
          }
        ]


  YT.ready () ->
    players = []
    if $('[data-video]').length > 0
      $('[data-video]').each (i, e) ->
        $this = $(e)

        $overlay  = $this.next()
        $video    = $this.parent()

        video_id  = $this.data('video')
        div_id    = $this.attr("id")

        players[div_id] = new YT.Player($this.attr("id"), {
          videoId: video_id
          playerVars:
            enablejsapi: 1
            showinfo: 0
            controls: 0
            autoplay: 0
            modestbranding: 0
            rel: 0
            playsinline: 1
            playlist: video_id
            origin: 'https://www.youtube.com'
          events: 
            'onReady': (event) ->

              $video.addClass('video-ready')

              # get and display video duration
              duration = event.target.getDuration()
              m = (~~(duration / 60)).toString().padStart(2,'0')
              s = (duration % 60).toString().padStart(2,'0')
              duration = [m,s].join ":"
              $overlay.attr('data-duration', duration)

              $overlay.on 'click', (e) ->
                e.preventDefault()

                console.log "click", e.target
                state = event.target.getPlayerState()
                console.log state
                if state in [-1, 2, 0, 5]
                  players[div_id].playVideo()
                if state in [1, 3]
                  players[div_id].pauseVideo()

            'onStateChange': (event) ->
              console.log "state chage", video_id, div_id, event.data
              if event.data > 0
                $video.addClass('video-nothumb')
              else
                $video.removeClass('video-nothumb')

              if (event.data == YT.PlayerState.PLAYING)
                $video.addClass('video-playing').removeClass('video-paused')
              else if (event.data == YT.PlayerState.PAUSED)
                $video.removeClass('video-playing').addClass('video-paused')
              else if (event.data == YT.PlayerState.ENDED)
                $video.removeClass('video-playing video-paused')

              

        })